<template>
  <div class="admin-partners-create">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/integration/partners')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Integration - Review Partner Integrations
      </h1>
    </div>
    <!-- / Page Header -->

    <!-- List wrapper -->
    <div class="partners-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No partners to show -->
      <a-alert
        v-if="!isLoading && partnersToReview.length == 0"
        type="info"
        message="No partners to review"
      >
      </a-alert>
      <!-- / No partners to show -->

      <!-- Loaded -->
      <div class="partners-list" v-if="!isLoading && partnersToReview.length">
        <a-row type="flex" :gutter="20">
          <a-col :span="colSpan" v-for="partner in partnersToReview" :key="partner.id">
            <api-client-list-item
              @selected="
                $router.push('/admin/integration/partners/create/' + partner.id)
              "
              :tenant-id="tenantId"
              :api-client="partner"
            ></api-client-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiClientListItem from "../ApiClients/ApiClientListItem.vue";
export default {
  components: { ApiClientListItem },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    // if (this.isMyPresenceHidden) {
    //   this.$message.info("You don't have permission to create an API client");
    //   this.$router.push('/admin/integration/api-clients');
    // }
  },

  watch: {
    selectedOrganisation() {
      this.loadApiClients();
    },
  },

  methods: {
    ...mapActions("adminApiClients", {
      loadApiClients: "loadApiClients",
    })
  },

  computed: {
     ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
      isMyPresenceHidden: "isMyPresenceHidden"
    }),

    ...mapGetters("adminApiClients", {
      isLoading: "isLoading",
      partnersToReview: "partnersToReview",
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    }
  }
};
</script>

<style>
</style>